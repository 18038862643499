import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default class ContactPage extends Component {
    state = {};

    render() {
        return (
            <Layout>
                <SEO
                    keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                    title="Contact"
                />
                <section>
                    <form className="mx-auto md:w-1/2">
                        <p className="mb-2 leading-loose text-black font-bold">
                            This doesnt work....yet. Just here to look pretty.
                        </p>
                        <p className="mb-8 leading-loose text-black font-bold">
                            But feel free to email me via the link on the
                            bottom.
                        </p>

                        <label
                            className="block mb-2 text-xs font-bold uppercase "
                            htmlFor="first-name">
                            First Name
                        </label>

                        <input
                            className="w-full mb-6 form-input bg-gray-600 text-white"
                            id="first-name"
                            placeholder="Paul"
                            type="text"
                        />

                        <label
                            className="block mb-2 text-xs font-bold uppercase"
                            htmlFor="last-name">
                            Last Name
                        </label>

                        <input
                            className="w-full mb-6 form-input bg-gray-600 text-white"
                            id="last-name"
                            placeholder="Stanley"
                            type="text"
                        />

                        <label
                            className="block mb-2 text-xs font-bold uppercase"
                            htmlFor="email">
                            Email
                        </label>

                        <input
                            className="w-full mb-6 form-input bg-gray-600 text-white"
                            id="email"
                            placeholder="paul@therealkissarmy.com"
                            type="text"
                        />

                        <label
                            className="block mb-2 text-xs font-bold uppercase"
                            htmlFor="message">
                            Message
                        </label>

                        <textarea
                            className="w-full mb-6 form-textarea bg-gray-600 text-white"
                            id="message"
                            placeholder="Kiss Rocks!😝"
                            rows="8"
                        />

                        <button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600">
                            Submit
                        </button>
                    </form>
                </section>
            </Layout>
        );
    }
}
